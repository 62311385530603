import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Notification from "../components/Notification"

const Kontact = () => (
  <Layout>
    <SEO title="TDCS Rosenheim" />
    <Notification />

    <h1>Kontact</h1>

    <h3>Dr. Badoiu George Vichente</h3>

    <h4 style={{ margin: "40px 0 10px 0" }}>Adresse</h4>
    <a
      target="_blank"
      href="https://www.google.com/maps/search/83075+Bad+Feilnbach+Sulzbergerstrasse+14/@47.5046293,12.6440545,6.2z"
    >
      83075 Bad Feilnbach Sulzbergerstrasse 14, Rosenheim, DEUTSCHLAND
    </a>

    <h4 style={{ margin: "40px 0 10px 0" }}>Tel. oder WhatsApp</h4>
    {/* <p>Mail: office@cdcsrosenheim.de</p> */}
    <a href="tel:016092387165">016092387165</a>

    <h4 style={{ margin: "40px 0 10px 0" }}>Mail</h4>
    <a href="mailto:elektrotherapie@yahoo.com">elektrotherapie@yahoo.com</a>
  </Layout>
)

export default Kontact
